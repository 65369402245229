<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M88 58.2295V168.229M168 88.2295V198.229M174.708 244.875L239.708 212.375C244.79 209.835 248 204.641 248 198.959V32.5C248 21.3493 236.265 14.0968 226.292 19.0836L174.708 44.8754C170.485 46.9868 165.515 46.9868 161.292 44.8754L94.7082 11.5836C90.4853 9.47214 85.5147 9.47214 81.2918 11.5836L16.2918 44.0836C11.21 46.6245 8 51.8184 8 57.5V223.959C8 235.11 19.7347 242.362 29.7082 237.375L81.2918 211.584C85.5147 209.472 90.4853 209.472 94.7082 211.584L161.292 244.875C165.515 246.987 170.485 246.987 174.708 244.875Z"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
